<template>
    <div style="
      width: 100%;
      height: 100%;
      background: linear-gradient(#FDE9E2, #F5B8BF);
    ">
        <div class="mainWrap">
            <div class="mainBar" :style="{
                // backgroundImage: 'url(' + (winW > 1920 ? bannerBg2x : bannerBg) + ')',
                backgroundImage: 'url(' + bannerBg + ')',
            }">
                <headerWX class="headerWX"></headerWX>
                <div class="hallWrap">
                    <!-- 双十一福利大放送 -->
                    <div class="get">
                        <img src="@/assets/imgs/DoubleEleven/get.png" alt="">
                        <div class="get_body">
                            <div class="item" v-for="(item, index) in getDatas.Data">
                                <div class="title">{{ item }}</div>
                                <div class="btn">
                                    <router-link :to="'/recharge'"><img v-if="index == 'rechargeMoney'"
                                            src="@/assets/imgs/DoubleEleven/TopUpBtn.png"></router-link>

                                    <img v-if="getDatas.status == 0 && index == 'noReturnMoney'"
                                        style="filter: grayscale(1);" src="@/assets/imgs/DoubleEleven/GetBtn.png"
                                        alt="">
                                    <img v-else-if="getDatas.status == 1 && index == 'noReturnMoney'"
                                        src="@/assets/imgs/DoubleEleven/GetBtn.png" @click="Getdata" alt="">
                                    <!-- <img v-else src="@/assets/imgs/DoubleEleven/OverBtn.png" alt=""> -->
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- 双十一福利榜 -->
                    <div class="list">
                        <img src="@/assets/imgs/DoubleEleven/list.png" alt="">
                        <div class="list_body">
                            <div class="item" v-for="(item, index) in listDatas" :key="index">
                                <div class="ranking">{{ item.rank }}</div>
                                <div class="name">{{ item.usernick }}</div>
                                <div class="award">{{ item.money }}</div>
                            </div>
                        </div>

                        <div class="UpdateTime">
                            {{ UpdateTime }}
                        </div>
                    </div>

                    <!-- 双十一说明 -->
                    <div class="ActivityExplain">
                        <img src="@/assets/imgs/DoubleEleven/ActivityExplain.png" alt="">
                        <div>
                            <p>1.2024年11月8日12点—2024年11月17日24点期间，玩家充值任意金额即可通过本活动页面领取等额绑定白金币。</p>
                            <p>2.本次活动奖励与首冲奖励可叠加。</p>
                            <p>3.活动期间累计获取活动奖励排名前十的玩家还将分别获得以下道具</p>
                            <p>&nbsp;&nbsp;&nbsp;第一名：睡梦云彩、从天而降啦、FLY流云绯色翅膀</p>
                            <p>&nbsp;&nbsp;&nbsp;第二名：从天而降啦、FLY流云绯色翅膀 </p>
                            <p>&nbsp;&nbsp;&nbsp;第三名：FLY流云绯色翅膀</p>
                            <p>&nbsp;&nbsp;&nbsp;入围奖：幸运钥匙x20</p>
                            <p>4.排行榜数据每10分钟刷新一次 </p>
                            <p>5.活动奖励将在活动结束后七个工作日内发放。 </p>
                            <p>6.活动返还绑定白金币请于本活动结束前及时领取，逾期不补。</p>

                        </div>
                    </div>

                </div>
            </div>
            <footerWX></footerWX>

            <!-- 二次确认弹窗 -->
            <!-- <div id="mask" v-if="ispopUp">
                <div
                    style=" position: fixed; top: 13%; left: 35%; border-radius: 10px ;width: 30vw; height: 70vh; min-width: 600px; padding: 5px; background: linear-gradient(#d7b7ff, #f9f8ff); z-index: 9999; ">
                    <popUp @ispopUp="TzpopUp" propShow="3" :DBF_goShop="DBF_goShop"></popUp>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
// import menuWX from '@/components/Menu'
import axios from "axios";
import Cookies from "js-cookie";
import headerWX from "@/components/Header";
import footerWX from "@/components/Footer";
// import popUp from "@/components/pop_up";

export default {
    name: "midAutumn",
    components: {
        // menuWX,
        headerWX,
        footerWX,
        // popUp,
    },
    data() {
        return {
            // 背景
            bannerBg: require("@/assets/imgs/DoubleEleven/bannerBg.png"),
            // 双十一福利大放送
            getDatas: {
                status: 0,
                Data: {
                    rechargeMoney: 0,
                    noReturnMoney: 0,
                    returnMoney: 0
                }

            },
            // 双十一福利榜
            listDatas: [],

            // 更新时间
            UpdateTime: ''
        };
    },
    created() {
        this.getHallFameData();
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    },
    methods: {
        getHallFameData() {
            axios({
                method: "post",
                url: "/activity/getRechargeInfo",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2024singles_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        const item = res.data.data
                        this.getDatas.status = item.status;
                        this.getDatas.Data.rechargeMoney = item.rechargeMoney;
                        this.getDatas.Data.noReturnMoney = item.noReturnMoney;
                        this.getDatas.Data.returnMoney = item.returnMoney;

                        // this.getActivityData.todayData.getNum = 10;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
            // 活动追加列表
            axios({
                method: "post",
                url: "/activity/getRechargeRank",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2024singles_activity",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        console.log(res.data.data.title.split('：')[1], '数据哒哒哒');
                        this.listDatas = res.data.data.rankInfoList;
                        this.UpdateTime = res.data.data.title.split('：')[1]


                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },


        // 领取充值奖励
        Getdata() {
            axios({
                method: "post",
                url: "/activity/getRechargePrize",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "2024singles_activity",
                    platForm: "web",
                    userId: Cookies.get("wx_userId"), // userId
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        this.$message.success(res.data.message);
                        this.getHallFameData();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        }
    },
};
</script>

<style lang="less" scoped>
@media (max-width: 1440px) {
    .mainWrap {
        zoom: 0.75;
    }
}

@media (max-width: 1208px) {
    .mainWrap {
        zoom: 0.6;
    }
}

.headerWX {
    margin: 0;

    /deep/ .header {
        // background: #0c0c0c4f;
        background: rgba(255, 255, 255, 0.8);
    }
}

#mask {
    width: 100%;
    height: 100%;
    // opacity: 0.6;
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 999;
}

.mainWrap {
    margin: auto;
    max-width: 1900px;
    min-width: 1900px;
    min-height: 100vh;
    background: linear-gradient(180deg, #300055 0%, #17182a 100%);
    // background-image: url("../../assets/imgs/Halloween/bannerBg.png");

    .mainBar {
        color: #333333;
        height: 2375px;
        background-size: cover;
        /* 使背景图像覆盖整个区域 */
        background-position: center;
        /* 居中显示 */
        width: 100%;
        /* 或设定具体宽度 */
        // aspect-ratio: 8 / 10;
        /* 根据实际宽高比设置 */
        /* 设置宽度 */
        // min-height: 100vh;
    }
}

.hallWrap {
    min-height: 100vh;
    width: 70%;
    // background-color: #4528af27;
    margin: auto;
    // padding-bottom: 20px;
    position: relative;

    .get {
        text-align: center;
        position: relative;

        .get_body {
            position: absolute;
            left: 49.7%;
            transform: translateX(-50%);
            bottom: 40px;
            width: 73%;
            height: 33%;
            display: flex;

            .item {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                .title {
                    color: #fff;
                    // text-shadow: 1px 1px 3px #fe360b;
                    font-size: 38px;
                    font-weight: 700;
                    -webkit-text-stroke: 2px #fe360b;
                }
            }

            >div:nth-child(2) {
                margin: 0 60px;
            }
        }
    }

    // 双十一福利榜
    .list {
        margin-top: 100px;
        text-align: center;
        position: relative;

        .list_body {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 35%;
            width: 90%;
            // height: 51%;

            .item {
                height: 11%;
                display: flex;

                >div {
                    flex: 1;
                    line-height: 50px;
                    font-size: 28px;
                    color: #fff;
                }

                >div:nth-child(2) {
                    margin: 0 8px;
                }
            }
        }


        .UpdateTime {
            font-family: JiangChengYuanTi, JiangChengYuanTi;
            position: absolute;
            left: 35%;
            transform: translateX(-50%);
            bottom: 2%;
            height: 5%;
            text-align: left;
            line-height: 45px;
            font-size: 28px;
            font-weight: 800;
            color: #fff;
        }
    }

    // 双十一说明
    .ActivityExplain {
        width: 1199px;
        height: 450px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 20px 0px #FFFFFF;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #FF0000;
        margin: auto;
        margin-top: 100px;
        text-align: center;

        img {
            margin: 15px 0;
        }

        >div {
            text-align: left;

            p {
                padding-left: 20px;
                height: 35px;
                font-size: 20px;
                font-weight: 700;
                color: #ffffff;
                // -webkit-text-stroke: 1px #fe360b;
                text-shadow:
                    -1px -1px 0 rgb(255, 0, 0),
                    1px -1px 0 rgb(255, 0, 0),
                    -1px 1px 0 rgb(255, 0, 0),
                    1px 1px 0 rgb(255, 0, 0);
                /* 形成边框的效果 */
            }
        }
    }

}
</style>
